import { useRouter } from "next/router"

import ChessHeadImage4 from "../assets/images/header-images/chess-4.jpg";

const NotFound = () => {
    const router = useRouter();

    return (
        <div className="error-404">
            <div className="content">
                <div className="left-side">
                    <img src={ChessHeadImage4.src} />
                </div>
                <div className="right-side">
                    <p className="title">Oops! Something is clearly wrong here...</p>
                    <p className="description">The page you are looking for doesn’t exist. (404)</p>
                    <button className="btn" onClick={() => router.push('/')}>
                        Go to homepage
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NotFound